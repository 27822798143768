/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import { get } from '@/utils/request';

import Header from '@/components/Header.jsx';
import Banner from '@/components/Banner.jsx';
import Content from '@/components/Content/Index.jsx';
import Footer from '@/components/Footer.jsx';

import '@/less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      pageData: {},
      banners: [],
      show: false,
      detailParams: {},
    };
    this.getOemInfo = this.getOemInfo.bind(this)
    this.getOemDetail = this.getOemDetail.bind(this)
  }

  async getOemInfo() {
    const { data } = await get('/api/sysmgr-web/oem/query-oem-config-by-domain', {
      domain: window.location.host
    })
    const { platformId, companyId, platformType, tenantId, oemLevel } = data
    window.localStorage.setItem('platformId', platformId)
    // 获取配置信息
    this.getOemDetail({
      platformId,
      companyId,
      platformType,
      tenantId,
      oemLevel
    })
  }

  async getOemDetail({ platformId, platformType, companyId, tenantId, oemLevel }) {
    if(platformId && platformType) {
      const body = { platformId, platformType, companyId }
      if(oemLevel === 1) {
        Object.assign(body, {
          oemLevel,
          companyId: tenantId,
        })
      }
      const { data } = await get('/api/oem-official-website/oem/setting/detail', body)
      const { files } = data || { files: [] }
      const banners = (files.length && files.filter(el => el.fileKey === 'banner')) || []
      document.title = data.companyName || '广州合摩计算机科技有限公司'
      const logo = files.length && files.find(el => el.fileKey === 'logo')
      // 动态设置网站ico
      const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'icon'
      link.href = logo && logo.downloadCode ? `/api/sysmgr-web/file/download?downloadCode=${logo.downloadCode}` : '/favicon.ico'
      document.getElementsByTagName('head')[0].appendChild(link)
      this.setState({
        pageData: data || { files: [] },
        banners,
        show: true,
      })
    } else {
      document.title = '广州合摩计算机科技有限公司'
      this.setState({
        pageData: { files: [] },
        banners: [],
        show: true,
      })
    }
  }

  componentWillMount() {
    // 获取oem信息
    this.getOemInfo()
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    const children = [
      <Header
        id="Nav3_0"
        key="Nav3_0"
        isMobile={this.state.isMobile}
        pageData={this.state.pageData}
      />,
      <Banner
        id="Banner0_0"
        key="Banner0_0"
        isMobile={this.state.isMobile}
        title="一站式企业灵活用工服务平台"
        banners={this.state.banners}
      />,
      <Content
        id="Content_1"
        key="Content_1"
        isMobile={this.state.isMobile}
        pageData={this.state.pageData}
      />,
      <Footer
        id="Footer1_0"
        key="Footer1_0"
        isMobile={this.state.isMobile}
        pageData={this.state.pageData}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {this.state.show ? children : null}
      </div>
    );
  }
}
